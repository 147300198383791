export default {
  modelName: "tulpa_page_template",
  label: "page_template",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "page_template_name",
      rules: 'required',
      autofocus: true,
      labelInLocale: true,
    },
    tags: {
      type: 'tags',
      label: 'Tags'
    },
    remark: {
      type: 'textarea',
      label: 'remark',
      labelInLocale: true,
    },
    content: {
      type: "payload",
      label: "content",
    },
    updated_at: {
      type: "datetime",
      label: "updated_at",
      readonly: true,
      labelInLocale: true,
    },
    updated_admin: {
      type: "belongsTo",
      label: "updated_admin",
      readonly: true,
      textKey: "name",
      modeldataName: "admin",
      labelInLocale: true,
    },
    created_admin: {
      type: "belongsTo",
      label: "created_admin",
      readonly: true,
      textKey: "name",
      modeldataName: "admin",
      labelInLocale: true,
    },
  },
}